<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>优惠券</el-breadcrumb-item>
            <el-breadcrumb-item>{{action === 'add' ? '新增' : action === 'edit' ? '编辑' : '复制'}}{{ reversedCouponType }}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="pre-sale-main">
            <el-row class="left-img">
               <el-row class="top-title">
                  <p class="p1">{{ ruleForm.mainTitle || '优惠券名称' }}</p>
                  <p class="p2">{{ ruleForm.subTitle || '优惠券副标题' }}</p>
                  <p class="p3">有效期：{{ reversedDateRange || '0000-00-00 0000-00-00' }}</p>
               </el-row>
               <el-row class="btm-detail">
                  <p>限领数量：<span>每人限领取{{ ruleForm.limitQuantity }}张</span></p>
                  <p>参与对象：<span>{{ ruleForm.thresholdType === 'ALL' ? '全部客户' : '指定客户' }}</span></p>
                  <p>使用业务：<span>{{ hotelTypeInfo.scopeType === 'ALL' ? '所有房型' : '限定房型' }}、{{ productInfo.scopeType === 'ALL' ? '所有商品' : '限定商品' }}</span></p>
               </el-row>
            </el-row>
            <el-form class="right-form" :model="ruleForm" :rules="rules" ref="ruleForm">
               <el-form-item label="优惠券名称" prop="mainTitle">
                  <el-input v-model="ruleForm.mainTitle" class="width-220"></el-input>
               </el-form-item>
               <el-form-item label="优惠券副标题">
                  <el-input  v-model="ruleForm.subTitle" class="width-220"></el-input>
               </el-form-item>
               <el-form-item label="优惠券颜色" prop="color">
                  <el-color-picker v-model="ruleForm.color"></el-color-picker>
               </el-form-item>
               <el-form-item label="剩余库存" prop="issueCount">
                  <el-input class="m-right-10 width-220" placeholder="0" v-model="ruleForm.issueCount" oninput="value=value.replace(/[^\d]/g,'')">
                     <template slot="append">张</template>
                  </el-input>
               </el-form-item>
               <el-form-item label="使用条件" v-if="type !== 'EXCHANGECOUPON'" prop="useThreshold">
                  <el-row class="content flex">
                     <span class="m-right-10 size">消费金额满</span>
                     <el-input class="m-right-10 width-120"  placeholder="0" v-model="ruleForm.useThreshold" oninput="value=value.replace(/[^\d]/g,'')">
                        <template slot="append">元</template>
                     </el-input>
                  </el-row>
               </el-form-item>
               <el-form-item label="减免金额" v-if="type !== 'EXCHANGECOUPON'" prop="moneyType">
                  <el-row class="content">
                     <el-row>
                        <el-radio v-model="ruleForm.moneyType" label="FIXED_AMOUNT" class="size">
                           <span class="size">固定金额</span>
                           <el-input
                                 class="m-right-10 width-120"
                                 placeholder="0" v-model="ruleForm.fixMoney"
                                 :disabled="ruleForm.moneyType !== 'FIXED_AMOUNT'"
                                 @input="verificationNum">
                              <template slot="append">元</template>
                           </el-input>
                        </el-radio>
                     </el-row>
                     <el-row style="margin-top: 10px">
                        <el-radio v-model="ruleForm.moneyType" label="RANDOM_QUOTA" class="size">
                           <span class="size">随机金额</span>
                           <el-input
                                 class="m-right-10 width-120"
                                 placeholder="0" v-model="ruleForm.lowMoney"
                                 :disabled="ruleForm.moneyType !== 'RANDOM_QUOTA'"
                                 @input="verificationNum"
                           >
                              <template slot="append">元</template>
                           </el-input>
                           <span class="size">至</span>
                           <el-input class="m-right-10 width-120"
                                     placeholder="0" v-model="ruleForm.uppMoney"
                                     :disabled="ruleForm.moneyType !== 'RANDOM_QUOTA'"
                                     @input="verificationNum"
                           >
                              <template slot="append">元</template>
                           </el-input>
                        </el-radio>
                     </el-row>
                  </el-row>
               </el-form-item>
               <el-form-item label="有效期" prop="validType">
                  <el-row class="content">
                     <el-row>
                        <el-radio v-model="ruleForm.validType" label="APPOINT_DATE">
                           <span class="size">指定日期</span>
                           <el-date-picker
                                 class="width-280"
                                 v-model="ruleForm.dateRange" type="daterange"
                                 value-format="yyyy-MM-dd HH:mm:ss"
                                 :default-time="['00:00:00', '23:59:59']"
                                 :disabled="ruleForm.validType !== 'APPOINT_DATE'"
                                 :clearable="false"
                                 placeholder="选择日期">
                           </el-date-picker>
                           <span class="size m-left-5">请至少选择一天</span>
                        </el-radio>
                     </el-row>
                     <el-row class="d-flex m-top-15">
                        <el-row class="m-right-30">
                           <el-radio v-model="ruleForm.validType" label="RECEIVE_DATE">
                              <span class="size">领取以后</span>
                           </el-radio>
                           <el-input class="m-right-10 width-120" @input="verificationNum"
                                     placeholder="0" v-model="ruleForm.validDays" :disabled="ruleForm.validType !== 'RECEIVE_DATE'" >
                              <template slot="append">天</template>
                           </el-input>
                           <span class="size">生效</span>
                        </el-row>
                        <el-row>
                           <span class="size">有效期</span>
                           <el-input class="m-right-10 width-120"
                                     placeholder="0" v-model="ruleForm.expiraDays"
                                     :disabled="ruleForm.validType !== 'RECEIVE_DATE'"
                                     @input="verificationNum">
                              <template slot="append">天</template>
                           </el-input>
                        </el-row>
                     </el-row>
                  </el-row>
               </el-form-item>
               <el-form-item label="适用范围" v-if="type !== 'EXCHANGECOUPON'" prop="applyTypes">
                  <el-row class="content">
                     <!-- 商城 -->
                     <el-row class="m-bottom-20">
                        <el-checkbox v-model="ruleForm.applyTypes" label="PRODUCT">商城</el-checkbox>
                        <el-radio :disabled="!ruleForm.applyTypes.includes('PRODUCT')" v-model="productInfo.scopeType" label="ALL" style="margin-top: 8px">全部商品</el-radio>
                        <el-radio :disabled="!ruleForm.applyTypes.includes('PRODUCT')" v-model="productInfo.scopeType" label="SPECIFIC_TYPE" style="margin-top: 8px">指定商品</el-radio>
                        <el-row v-show="productInfo.scopeType === 'SPECIFIC_TYPE' && ruleForm.applyTypes.includes('PRODUCT')" style="text-align: right; width: 400px">
                           <el-button plain >选择商品</el-button>
                        </el-row>
                     </el-row>
                     <!-- 订房 -->
                     <el-row class="flex">
                        <el-checkbox class="m-right-30" v-model="ruleForm.applyTypes" label="HOTEL_TYPE">订房</el-checkbox>
                        <el-row>
                           <el-row class="m-bottom-10">
                              <!-- <el-radio :disabled="!ruleForm.applyTypes.includes('HOTEL_TYPE')" v-model="hotelTypeInfo.scopeType" label="ALL">所有房型</el-radio>-->
                              <el-radio :disabled="!ruleForm.applyTypes.includes('HOTEL_TYPE')" v-model="hotelTypeInfo.scopeType" label="SPECIFIC_TYPE">部分房型</el-radio>
                              <el-button type="primary"  v-show="hotelTypeInfo.scopeType === 'SPECIFIC_TYPE' && ruleForm.applyTypes.includes('HOTEL_TYPE')" @click="roomTypeVisible = true">添加房型</el-button>
                           </el-row>
                           <el-row>
                              <el-table
                                    v-show="hotelTypeInfo.scopeType === 'SPECIFIC_TYPE' && ruleForm.applyTypes.includes('HOTEL_TYPE')"
                                    :data="roomTypeData" border class="hotel-table">
                                 <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
                                 <el-table-column prop="name" label="房型名称"></el-table-column>
                              </el-table>
                           </el-row>
                        </el-row>
                     </el-row>
                  </el-row>
               </el-form-item>
               <el-form-item label="详情设置" prop="remark">
                  <el-input type="textarea" :rows="2" class="width-300" placeholder="请输入内容" v-model="ruleForm.remark"></el-input>
               </el-form-item>
               <el-form-item label="客服电话">
                  <el-input class="width-220" v-model="ruleForm.servicePhone"  @input="verificationMobile" />
               </el-form-item>
               <el-form-item label="领取人限制" prop="thresholdType">
                  <el-row class="content">
                     <el-radio-group v-model="ruleForm.thresholdType">
                        <el-radio label="ALL">不限制，所有人都能领取</el-radio>
                        <el-radio label="DESIGNATED_CUSTOMER">指定客户身份可领</el-radio>
                        <el-radio label="GIFT">赠品专用</el-radio>
                     </el-radio-group>
                     <el-button v-show="ruleForm.thresholdType === 'DESIGNATED_CUSTOMER'" type="primary"  @click="changeThresholdType">选择客户身份</el-button>
                     <el-table
                           v-if="ruleForm.thresholdType === 'DESIGNATED_CUSTOMER'"
                           :data="selectTagGroups"
                           border
                           class="hotel-table">
                        <el-table-column prop="groupName" label="群组名"></el-table-column>
                        <el-table-column prop="memberCount" label="会员数量"></el-table-column>
                     </el-table>
                  </el-row>
               </el-form-item>
               <el-form-item label="每人限领" prop="limitQuantity">
                  <el-row class="content">
                     <el-input class="m-right-10 width-120"  placeholder="0" v-model="ruleForm.limitQuantity"
                               @input='verificationNum'
                               >
                        <template slot="append">张</template>
                     </el-input>
                     <span class="size">每个用户的的领券上限，不填则默认为1</span>
                  </el-row>
               </el-form-item>
               <el-form-item label="分享设置">
                  <el-checkbox v-model="ruleForm.isShared">优惠券允许分享给好友领取</el-checkbox>
               </el-form-item>
               <el-form-item>
                  <el-button class="width-120 bg-gradient"  type="primary" :disabled="submitState" @click="handleSave">提交</el-button>
               </el-form-item>
            </el-form>
         </el-row>
      </el-row>
      <!-- 选择房型 -->
      <el-dialog
            title="选择房型"
            :visible.sync="roomTypeVisible"
            width="30%">
         <el-tree
               ref="tree" :props="defaultProps" :load="loadNode"
               @check-change="changeCheck" :default-expanded-keys="expandedKeys"
               lazy :show-checkbox="true"
               node-key="id" :default-checked-keys="checkedKeys"></el-tree>
         <span slot="footer">
            <el-button @click="roomTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="getCheckedRoomTypes()">确 定</el-button>
         </span>
      </el-dialog>
      <!-- 商品选择 -->
      <el-dialog
            title="商品选择"
            width="30%">
         <el-row style="margin: 0.5rem 0">
            <span>商品分类</span>
            <el-select style="margin-left: 0.5rem"  placeholder="请选择">
               <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
         </el-row>
         <el-table
               :data="[]"
               tooltip-effect="dark"
               style="width: 100%">
            <el-table-column type="selection" label="选择" width="55"></el-table-column>
            <el-table-column label="封面">
               <template slot-scope="scope">
                  <img :src="scope.row.image">
               </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="address" label="价格"></el-table-column>
         </el-table>
         <span slot="footer">
            <el-button>取 消</el-button>
            <el-button type="primary">确 定</el-button>
         </span>
      </el-dialog>
      <!-- 客户身份 -->
      <el-dialog
            title="选择群组"
            :visible.sync="tagGroupVisible"
            width="30%">
         <el-table
               ref="multipleTable"
               :data="tagGroupData"
               tooltip-effect="dark"
               style="width: 100%"
               @selection-change="handleSelected">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" width="60">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="groupName" label="群组名"><template></template></el-table-column>
            <el-table-column prop="memberCount" label="会员数量" width="120"></el-table-column>
         </el-table>
         <el-pagination
               layout="prev, pager, next" :total="tagGroupTotal"
               small :page-size="limit" @current-change="changeCurrPage">
         </el-pagination>
         <span slot="footer" class="dialog-footer">
            <el-button  @click="tagGroupVisible = false">取 消</el-button>
            <el-button type="primary"  @click="tagGroupVisible = false">确 定</el-button>
         </span>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj  } from "@/api/interface";
import { label } from "@/api/interface/data";
import { coupon } from "@/api/interface/business";
import { pms } from "@/api/interface/pms";
import { mapState } from 'vuex'
export default {
   data(){
      let validThreshold = (rule, value, callback) => {
         if (value === 'NO') {
            if (this.selectTagGroups.length === 0) {
               callback(new Error('请选择指定客户'))
            }else {
               callback()
            }
         }else {
            callback()
         }
      }
      let validMoneyType = (rule, value, callback) => {
         if (value === 'RANDOM_QUOTA') {
            if (this.ruleForm.lowMoney <= 0 ) {
               callback(new Error('最低金额不能等于0'))
            }else if (this.ruleForm.lowMoney >= this.ruleForm.uppMoney) {
               callback(new Error('最低金额不能大于等于最高金额'))
            }else {
               callback()
            }
         }else {
            if (this.ruleForm.fixMoney <= 0) {
               callback(new Error('固定金额不能等于0'))
            }else {
               callback()
            }
         }
      }
      let validValidType = (rule, value, callback) => {
         if (value === 'RECEIVE_DATE') {
            if (this.ruleForm.expiraDays <= 0 || this.ruleForm.validDays < 0) {
               callback(new Error('有效期不能小于0'))
            }else {
               callback()
            }
         } else {
            if (this.ruleForm.dateRange.length === 0) {
               callback(new Error('请选择指定日期'))
            }else {
               callback()
            }
         }
      }
      let validApplyTypes = (rule, value, callback) => {
         if (value.includes('HOTEL_TYPE')) {
            if (this.hotelTypeInfo.scopeType === 'SPECIFIC_TYPE' && this.roomTypeData.length === 0) {
               callback(new Error('请选择指定房型'))
            }else {
               callback()
            }
         }else {
            callback()
         }
      }
      return {
         defaultProps: {
            children: 'children',
            label: 'name',
            isLeaf: 'leaf'
         },
         submitState:false,              //提交按钮请求时为禁用状态
         tagGroupData: [],              // 群组列表
         checkedKeys: [],               // 默认选中节点
         expandedKeys: [],              // 展开的节点ID
         hotelTotal: 0,                 // 酒店总数
         tagGroupTotal: 0,              // 群组总数
         limit: 7,                      // 每页数
         hotelPage: 1,                  // 酒店当前页
         tagGroupPage: 1,               // 群组当前页
         type: '',                      // 路由类型
         roomTypeVisible: false,        // 房型对话框
         tagGroupVisible: false,        // 群组对话框
         action: '',
         accountType: '',
         hotelTypeInfo: {
            type: 'HOTEL_TYPE',
            scopeType: 'SPECIFIC_TYPE'
         },
         productInfo: {
            type: 'PRODUCT',
            scopeType: 'ALL'
         },
         productData: [],               // 适用指定商品显示列表
         roomTypeData: [],              // 适用指定房型显示列表
         roomTypeArr: [],               // 适用指定房型提交数组
         selectTagGroups: [],           // 编辑时 当前选中群组
         options: [{
            value: '选项1',
            label: '全部'
         }, {
            value: '选项2',
            label: '套房'
         }],
         textarea:'',
         service:'',
         draw:'',
         order:'1',
         share: false,
         id: '',                        // 优惠券ID
         // goods: '1',                    // 商品商城
         validDate: '',                 // 生效时间
         expiraDate: '',                // 失效时间
         ruleForm: {
            mainTitle: '',                 // 名称
            subTitle: '',                  // 副标题
            color: '#ef8f4f',              // 颜色
            issueCount: 0,                 // 剩余库存
            useThreshold: 1,               // 使用条件
            servicePhone: '',              // 服务电话
            limitQuantity: 1,              // 每人限领数
            isShared: true,                // 允许分享好友
            thresholdType: 'ALL',          // 领取人限制
            moneyType: 'FIXED_AMOUNT',     // 减免金额类型
            lowMoney: 1,                   // 最低金额
            uppMoney: 2,                   // 最高金额
            fixMoney: 1,                   // 固定金额
            validType: 'APPOINT_DATE',     // 有效期类型
            validDays: 0,                  // 领取后天生效
            expiraDays: 1,                 // 有效天数
            dateRange: [],                 // 有效日期
            groupId: [],                   // 群组ID
            remark: '',                    // 详情设置
            applyTypes: [ 'HOTEL_TYPE', 'PRODUCT'],  // 适用范围
         },
         rules: {
            mainTitle: [{ required: true, message: '请输入优惠券名称', trigger: 'blur' }],
            color: [{ required: true, message: '请选择券背景颜色', trigger: 'change' }],
            issueCount: [{ required: true, message: '请输入剩余库存数', trigger: 'blur' }],
            useThreshold: [{ required: true, message: '请输入使用条件金额', trigger: 'blur' }],
            limitQuantity: [{ required: true, message: '请输入限领数', trigger: 'blur' }],
            thresholdType: [{ validator: validThreshold, trigger: 'change' }],
            moneyType: [{ validator: validMoneyType, trigger: 'change' }],
            validType: [{ validator: validValidType, trigger: 'change' }],
            applyTypes: [{ validator: validApplyTypes, trigger: 'change' }]
         },
         confirm: '确定',
      }
   },
   computed: {
      ...mapState(['hotelInfo']),
      reversedCouponType: function (){
         switch (this.type) {
            case "CASHCOUPON":
               return '代金券'
            case "EXCHANGECOUPON":
               return '兑换券'
            case "COUPON":
               return '折扣券'
         }
         return this.type
      },
      reversedDateRange: function (){
         if (this.ruleForm.dateRange.length > 0) {
            return this.ruleForm.dateRange[0].substring(0, 10) + ' ' + this.ruleForm.dateRange[1].substring(0, 10)
         }else {
            return ''
         }
      }
   },
   mounted() {
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo')).accountType
      this.type = this.$route.params.coupon_type
      this.action = this.$route.query.action
      this.id = this.$route.query.id
      this.action === 'add' && this.getTagGroup()
      if (this.action === 'edit' || this.action === 'copy') this.getCoupon()
   },
   beforeDestroy() {
      sessionStorage.removeItem('couponInfo')
   },
   methods: {
      // 限制输入11位数
      verificationMobile(){
         this.ruleForm.servicePhone = this.ruleForm.servicePhone.replace(/[^\d]/g,'')
         if(this.ruleForm.servicePhone >= 11){
            this.ruleForm.servicePhone = this.ruleForm.servicePhone.substring(0,11)
         }
      },
      //限制输入正整数
      verificationNum(){
         this.ruleForm.limitQuantity = this.ruleForm.limitQuantity + ''
         if(this.ruleForm.limitQuantity == 0) this.ruleForm.limitQuantity = this.ruleForm.limitQuantity.replace(/[^1-9]/g,'')
         else this.ruleForm.limitQuantity = this.ruleForm.limitQuantity.replace(/\D/g,'')
      },
      // 获取编辑信息
      getCoupon(){
         const url = coupon.couponDetail + `/${this.id}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               let couponInfo = res.records
               this.id = couponInfo.id
               this.ruleForm.mainTitle =  this.action === 'copy' ? couponInfo.mainTitle + '(副本)' : couponInfo.mainTitle
               for (const k in this.ruleForm) k in couponInfo && (this.ruleForm[k] = couponInfo[k])
               couponInfo.isShared === 'YES' ? this.ruleForm.isShared = true : this.ruleForm.isShared = false
               if (couponInfo.validType === 'APPOINT_DATE') this.ruleForm.dateRange.push(couponInfo.validDate, couponInfo.expiraDate)
               // 限领人为指定
               if (this.ruleForm.thresholdType === 'DESIGNATED_CUSTOMER') {
                  couponInfo.groupId && (this.ruleForm.groupId = couponInfo.groupId.split(','))
                  this.getTagGroup(() => {
                     if (this.ruleForm.groupId.length > 0) {
                        this.tagGroupData.forEach(item => {
                           if (this.ruleForm.groupId.includes(item.id)) this.selectTagGroups.push(item)
                        })
                     }
                  })
               }
               // 类型是代金券
               if (this.type === 'CASHCOUPON') {
                  this.ruleForm.useThreshold = couponInfo.useThreshold
                  this.ruleForm.moneyType = couponInfo.moneyType
                  if (this.ruleForm.moneyType === 'FIXED_AMOUNT') {
                     this.ruleForm.fixMoney = couponInfo.fixMoney
                  }else {
                     this.ruleForm.lowMoney = couponInfo.lowMoney
                     this.ruleForm.uppMoney = couponInfo.uppMoney
                  }
                  // 适用范围
                  this.ruleForm.applyTypes = []
                  let couponProductList = couponInfo.couponProductList || []
                  couponProductList.forEach(item => {
                     // 适用房型
                     if (item.type === 'HOTEL_TYPE') {
                        // 全部房型
                        if (item.hasOwnProperty('scopeType')) {
                           this.hotelTypeInfo.scopeType = 'ALL'
                        }
                        // 指定房型
                        if (item.hasOwnProperty('productId')){
                           this.hotelTypeInfo.scopeType = 'SPECIFIC_TYPE'
                           this.roomTypeData = item.productList.map(item => {
                              return {
                                 hotelId: item.hotelId,
                                 hotelName: item.hotelName,
                                 id: item.productId,
                                 name: item.hotelRoomTypeName
                              }
                           })
                        }
                        this.ruleForm.applyTypes.push('HOTEL_TYPE')
                     }
                     // 适用商城
                     if (item.type === 'PRODUCT') {
                        // 全部商品
                        if (item.hasOwnProperty('scopeType')) {
                           this.productInfo.scopeType = 'ALL'
                        }
                        // 指定商品
                        if (item.hasOwnProperty('productId')) {
                           this.productInfo.scopeType = 'SPECIFIC_TYPE'
                        }
                        this.ruleForm.applyTypes.push('PRODUCT')
                     }
                  })
                  // 设置房型
                  let hotelIds = new Set(this.roomTypeData.map(item => item.hotelId))
                  for (let val of hotelIds) {
                     let row = { hotelId: '', productIds:[] }
                     for (let item of this.roomTypeData) {
                        if (item.hotelId === val) {
                           row.hotelId = item.hotelId
                           row.productIds.push(item.id)
                        }
                     }
                     this.roomTypeArr.push(row)
                  }
               }
            }
         })
      },
      // 保存添加
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = coupon.addCoupon
            const param = {
               hotelId: this.hotelInfo.id,
               hotelCode: this.hotelInfo.hotelCode,
               companyId: this.hotelInfo.storeId,
               type: this.type,
               mainTitle: this.ruleForm.mainTitle,
               subTitle: this.ruleForm.subTitle,
               color: this.ruleForm.color,
               issueCount: this.ruleForm.issueCount,
               useThreshold: this.ruleForm.useThreshold,
               moneyType: this.ruleForm.moneyType,
               validType: this.ruleForm.validType,
               isShop: this.isShop ? 'YES' : 'NO', // problem : this.isShop 未定义，永远取值NO
               isRoom: this.isRoom ? 'YES' : 'NO', // problem : this.isRoom 未定义，永远取值NO
               remark: this.ruleForm.remark,
               servicePhone: this.ruleForm.servicePhone,
               thresholdType: this.ruleForm.thresholdType,
               limitQuantity: this.ruleForm.limitQuantity,
               isShared: this.ruleForm.isShared ? 'YES' : 'NO'
            }
            if (this.ruleForm.moneyType === 'FIXED_AMOUNT') {
               param.fixMoney = this.ruleForm.fixMoney
            } else {
               param.lowMoney = this.ruleForm.lowMoney
               param.uppMoney = this.ruleForm.uppMoney
            }
            if (this.ruleForm.validType === 'APPOINT_DATE') {
               param.validDate = this.ruleForm.dateRange[0]
               param.expiraDate = this.ruleForm.dateRange[1]
            } else {
               param.validDays = this.ruleForm.validDays
               param.expiraDays = this.ruleForm.expiraDays
            }
            if (this.ruleForm.thresholdType === 'DESIGNATED_CUSTOMER') {
               param.groupId = this.ruleForm.groupId.join()
            }
            // 适用范围 订房
            param.couponCheckInfo = []
            if (this.ruleForm.applyTypes.includes('HOTEL_TYPE')) {
               this.getCheckedRoomTypes(true)
               // 指定房型
               if (this.hotelTypeInfo.scopeType === 'SPECIFIC_TYPE') {
                  this.hotelTypeInfo.checkInfos = this.roomTypeArr
               }
               param.couponCheckInfo.push(this.hotelTypeInfo)
            }
            // 适用范围 商品
            if (this.ruleForm.applyTypes.includes('PRODUCT')){
               //  problem：需要获取选择商品 选中的商品push到applyProduct数组中
               // 指定商品
               if (this.productInfo.scopeType === 'SPECIFIC_TYPE') {
                  // this.productInfo.checkInfos = this.applyProduct
               }
               param.couponCheckInfo.push(this.productInfo)
            }
            if (this.action === 'edit') {
               url = coupon.editCoupon
               param.id = this.id
            }
            this.submitState = true
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.submitState = false
                  this.$message({
                     showClose: true,
                     message: '创建优惠券成功！',
                     type: 'success'
                  })
                  this.$router.push('/coupon_list')
               }
            }).catch((err=>{
               this.submitState = false
            }))
         })
      },
      // 新增酒店房型
      loadNode(node, resolve){
         if (node.level === 0) {
            // 获取酒店列表
            const url = urlObj.getHotelList
            const param = {}
            if (this.accountType === 'hotel') param.hotelId = this.hotelInfo.id
            else if (this.accountType !== 'PLATFORM') param.companyId = this.hotelInfo.storeId
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.hotelTotal = res.total
                  let hotelData = res.records
                  hotelData = hotelData.map(item => ({ id: item.id, name: item.hotelName, isEbooking: item.isEbooking }))
                  resolve(hotelData)
               }
            })
         } else {
            // 获取酒店下房型
            let url
            let param = { hotelId: node.data.id }
            // if (node.data.isEbooking === 'YES') param.roomTypeSource = 'EBOOKING'
            // if (node.data.isEbooking === 'NO') param.roomTypeSource = 'PMS'
            url = pms.pmsRoomTypes
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  let roomTypeData = res.records
                  if (roomTypeData.length < 1) return resolve([])
                  roomTypeData = roomTypeData.map(item => {
                     return { id: item.id, hotelId: item.hotelId, hotelName: node.data.name, name: item.roomTypeName, leaf: true }
                  })
                  this.checkedKeys = this.roomTypeData.map(item => item.hotelId === node.data.id && item.id)
                  this.roomTypeArr.forEach((item, index) => item.hotelId === node.data.id && this.roomTypeArr.splice(index, 1))
                  resolve(roomTypeData)
               }
            })
         }
      },
      // 选中当前酒店
      changeCheck(data) {
         this.expandedKeys = []
         if (!data.leaf) this.expandedKeys = [data.id]
      },
      // 获取选中房型
      getCheckedRoomTypes(bool = false){
         if (!this.$refs.tree) return
         let [ getHalfCheckedNodes , getCheckedNodes ] = [ this.$refs.tree.getHalfCheckedNodes(), this.$refs.tree.getCheckedNodes() ]
         let roomTypeData = [...getHalfCheckedNodes, ...getCheckedNodes]
         this.roomTypeData = getCheckedNodes.filter(item => item.hasOwnProperty('leaf'))
         this.roomTypeVisible = false
         if (bool) {
            roomTypeData.forEach(item => {
               let row = {hotelId: '', productIds: []}
               if (!item.hasOwnProperty('leaf')) {
                  row.hotelId = item.id
                  roomTypeData.forEach(item1 => item.id === item1.hotelId && row.productIds.push(item1.id))
                  this.roomTypeArr.push(row)
               }
            })
         }
      },
      // 获取群组列表
      getTagGroup(callback){
         const url = label.tagGroup
         const param = {
            hotelId: this.hotelInfo.id,
            limit: this.limit,
            page: this.tagGroupPage
         }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.tagGroupTotal = res.total
               this.tagGroupData = res.records.map(item => ({id: item.id,groupName: item.groupName,memberCount: item.memberCount}))
               callback && callback()
            }
         })
      },
      // 改变群组当前页
      changeCurrPage(num){
         this.tagGroupPage = num
         this.getTagGroup()
      },
      // 改变限制人条件
      changeThresholdType(){
         this.tagGroupVisible = true
         if (this.selectTagGroups.length > 0){
            this.selectTagGroups.forEach(item => this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(item, true)))
         }
      },
      // 当前选中群组
      handleSelected(val){
         this.selectTagGroups = val
         this.ruleForm.groupId = val.map(item => item.id)
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getTagGroup()
      }
   },
}
</script>

<style scoped lang="scss">
.cont{
   .el-pagination{ text-align: right }
   .pre-sale-main{
      display: flex; padding: 20px;
      background: #fff;
      .left-img{
         width: 390px; height: 694px; padding: 130px 17px 34px;
         background: url('../../../../assets/coupon-img.jpg') no-repeat;
         .top-title{
            padding: 110px 0 0 0; text-align: center;
            .p1 { font-size: 20px; margin-bottom: 25px }
            .p2 { font-size: 16px; margin-bottom: 15px; color: #666666 }
            .p3 { color: #999999 }
         }
         .btm-detail{
            margin: 150px 0 0 0; text-indent: 17px; line-height: 27px; font-size: 15px;
            span{color: #666666}
         }
      }
      .right-form{
         padding: 0 0 0 40px; width: 700px;
         > .el-row{ margin-bottom: 10px }
         .tit{ font-size: 18px; margin-bottom: 30px }
         line-height: 28px; overflow: hidden;
         .name{
            width: 100px; text-align: right; float: left;
            i { font-size: 18px; color: red; margin-right: 5px }
         }
         .flex{ display: flex }
         .content{ float: left }
         .el-textarea { font-size: 12px }
         .hotel-table{ width: 100%; max-height: 200px; overflow-y: auto }
         .dialog-footer{ margin-top: 20px }
         .radio{ padding: 7px 0 }
         .m-left-right{ margin: 0 10px }
         .img-list{
            .el-row{ margin: 10px 10px 0 0 }
         }
         /* 标签 */
         .el-tag + .el-tag {
            margin-left: 10px;
         }
         .button-new-tag {
            margin-left: 10px; height: 32px; line-height: 30px; padding-top: 0;
            padding-bottom: 0;
         }
         .input-new-tag {
            width: 90px; margin-left: 10px; vertical-align: bottom;
         }
      }
   }
   .size{font-size: 12px;display: inline-block;margin-right: 10px}
}
</style>
